<template>
  <div class="text-right d-flex w-100 align-items-center">
    <span class="text-white opacity-90 mr-3">
      {{ currentUser.nome }}
    </span>
    <div>
      <button class="btn btn-outline-secondary text-white" @click="onLogout">
        Sair
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/store/auth.module";

export default {
  name: "KTQuickUser",
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  }
};
</script>
