<template>
  <div class="text-white text-center" style="width: 90px">
    <VueCountdown
      :time="time"
      @progress="handleEProgress"
      @end="handleEEnd"
      ref="timer"
    >
      <template slot-scope="props">
        Sessão: {{ formata(props.totalHours) }}:{{ formata(props.minutes) }}:{{
          formata(props.seconds)
        }}</template
      >
    </VueCountdown>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import { mapGetters } from "vuex";
import router from "@/core/router";
import store from "@/core/store";
import { VERIFY_AUTH, PURGE_AUTH } from "@/core/store/auth.module";
import MsgService from "@/core/services/msg.service";

export default {
  name: "KTTimer",
  components: { VueCountdown },
  computed: {
    ...mapGetters(["expToken"])
  },
  data() {
    return {
      time: 0,
      showReloadQuestion: false
    };
  },
  watch: {
    expToken: function () {
      this.start();
    }
  },
  methods: {
    formata(number) {
      return ("00" + number).slice(-2);
    },
    start() {
      const now = Date.now();
      const expToken = this.expToken * 1000; // miliseconds
      this.$refs.timer.abort();
      this.time = expToken - now > 0 ? expToken - now : 0;
      if (this.time > 0) {
        this.$refs.timer.start();
      }
    },
    async handleEProgress(data) {
      if (data.totalMinutes < 3 && !this.showReloadQuestion) {
        this.showReloadQuestion = true;
        const result = await MsgService.confirmReloadCountdown();

        if (result.isConfirmed) {
          this.reload();
        }
      }
    },
    handleEEnd() {
      if (this.time > 0 && this.showReloadQuestion) {
        this.showReloadQuestion = false;
        MsgService.show({
          title: "Sessão expirada",
          description: "",
          type: "warning"
        });

        store.commit(PURGE_AUTH);
        router.push({
          name: "login",
          query: { redirect: router.fullPath }
        });
      }
    },
    reload() {
      store.dispatch(VERIFY_AUTH);
      this.showReloadQuestion = false;
    }
  },
  mounted() {
    this.start();
  }
};
</script>
