<template>
  <div>
    <div v-if="hasManual">
      <div class="tipsbutton" @click="abreAjuda">
        <i class="fa fa-question"></i>
      </div>
    </div>
    <b-modal
      ref="modal-ajuda"
      size="xl"
      title="Ajuda"
      ok-only
      ok-variant="secondary"
      ok-title="Fechar"
    >
      <template v-if="hasManual">
        <h2>Manual</h2>
        <div class="accordion mb-5" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                class="text-left"
                block
                v-b-toggle.accordion-manual
                variant="secondary"
              >
                {{ manual.descricao_sessao }}
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-manual"
              visible
              accordion="accordion-manual"
              role="tabpanel"
            >
              <b-card-body>
                <div v-html="decodeHtml(manual.descricao)"></div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <template v-if="dicas.length">
          <h2>Dicas</h2>
          <div class="accordion" role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-for="(dica, index) in dicas"
              :key="'dica-' + index"
            >
              <b-card-header
                header-tag="header"
                class="p-1"
                role="tab"
                :style="{ cursor: 'auto' }"
              >
                <b-button
                  class="text-left"
                  block
                  v-b-toggle="'accordion-dicas-' + index"
                  variant="secondary"
                >
                  {{ dica.titulo }}
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'accordion-dicas-' + index"
                accordion="accordion-dicas"
                role="tabpanel"
              >
                <b-card-body>
                  <div v-html="decodeHtml(dica.descricao)"></div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </template>
      </template>
      <template v-else>Nenhum manual para exibir.</template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TipsButton",
  computed: {
    ...mapGetters(["manual", "dicas"]),
    hasManual() {
      return !!this.manual;
    }
  },
  methods: {
    abreAjuda() {
      this.$refs["modal-ajuda"].show();
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
  }
};
</script>

<style lang="scss">
.tipsbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  background-color: #6993ff;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
  opacity: 0.9;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  border-radius: 50%;
  color: #ffffff;

  i {
    color: #ffffff;
  }

  &:hover {
    opacity: 1;
    background-color: #4a7dff;
  }
}
</style>
